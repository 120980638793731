import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5481696d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-title-component" }
const _hoisted_2 = { class: "sub-title" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (value, key) => {
      return (_openBlock(), _createElementBlock("div", { key: key }, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(key), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(value), 1)
      ]))
    }), 128))
  ]))
}