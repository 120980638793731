export function loadPrivacyPolicyContent(language: string): string {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`@/data/privacyPolicy/${language}.json`)
  } catch (error) {
    console.warn(
      `Failed to load PrivacyPolicy for language ${language}:`,
      error
    )
    return ''
  }
}

export function loadTermsOfUseContent(language: string): string {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`@/data/termsOfUse/${language}.json`)
  } catch (error) {
    console.warn(`Failed to load TermsOfUse for language ${language}:`, error)
    return ''
  }
}
