
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
})
